@font-face {
  font-family: 'Prompt-Regular';
  src: url('resources/fonts/Prompt-Regular.ttf');
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('resources/fonts/Prompt-Bold.ttf');
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('resources/fonts/Prompt-Medium.ttf');
}

@font-face {
  font-family: 'SNT-Anouvong-Regular';
  src: url('resources/fonts/SNT Anouvong Regular.otf');
}

@font-face {
  font-family: 'SNT-Anouvong-Bold';
  src: url('resources/fonts/SNT Anouvong Bold Regular.otf');
}

@font-face {
  font-family: 'SNT-Anouvong-Medium';
  src: url('resources/fonts/SNT Anouvong Medium Regular.otf');
}

body {
  font-weight: 400;
  font-family: 'SNT-Anouvong-Regular';
  font-size: 20px;
}

.period td,
.period th {
  padding: .50rem !important;
}

.award-current-box {
  z-index: 2;
  background: #fff;
  border-radius: 10px;
  padding: 1rem;
}

.footer {
  width: 100%;
  background-color: #0077B6;
  color: white;
  text-align: center;
  font-size: 16px !important;
}

.header-gradient {
  background: rgb(2, 72, 212);
  background: radial-gradient(circle, rgba(2, 72, 212, 1) 0%, rgba(43, 128, 245, 1) 50%);
}

.announce {
  padding-top: 2rem;
}

.table-announce {
  margin: auto;
  width: 60% !important;
}

.point {
  cursor: pointer;
}